/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { Fragment, useEffect, useState } from "react";

import "./App.css";
import "./css/swiper-buttons.scss";
import "./css/vortex-slides.css";
import "./css/variable.css";
import "./css/style.css";

import Firebase from "./config/Firebase";
import { loginAction, logoutAction } from "./actions/UserActions";
import { useDispatch, useSelector } from "react-redux";
import { Switch, Route, Router, Redirect } from "react-router-dom";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import history from "./common/history";
// import { ROLES } from "./common/Constant";

import ModalPayment from "./components/ModalPayment";
import Home from "./screens/Home/Home";
import SyncDevice from "./screens/SyncDevice";
import MovieDetails from "./screens/MovieDetails";
import Contact from "./screens/Contact";
import Profile from "./screens/Profile";
import Faqs from "./screens/Faqs";
import WhoWeAre from "./screens/WhoWeAre";
import ExclusiveSection from "./screens/ExclusiveSection.jsx";
import HomeNoUser from "./screens/HomeNoUser";
import CineClub from "./screens/CineClub";
import Explore from "./screens/Explore";
import PanelControl from "./screens/PanelControl";
import MyList from "./screens/MyList/MyList";
import ResultSearch from "./screens/ResultSearch";
import Watch from "./screens/Watch";
import Login from "./screens/Login";
import ResetPassword from "./screens/ResetPassword";
import PanelControlUsers from "./screens/PanelControlUsers";
import Notifications from "./screens/Notifications";
// import UploadBanner from "./screens/UploadBanner";
import PanelControlSEO from "./screens/PanelControlSEO";
import { LoaderApp } from "./components/Loader";
import UploadGenre from "./screens/UploadGenre";
// import Festival from "./screens/Festival";
import UploadSubscriptionType from "./screens/UploadSubscriptionType";
import EditSubscriptions from "./screens/EditSubscriptions";
import UploadUserFree from "./screens/UploadUserFree";
import DeleteBanner from "./screens/DeleteBanner";
import DeleteSlider from "./screens/DeleteSlider";
import DeleteGenre from "./screens/DeleteGenre";
import DeleteMovie from "./screens/DeleteMovie";
import EditSlider from "./screens/EditSlider";
import MaintenanceMode from "./components/V2/MaintenanceMode";
// import { SUBSCRIPTIONS } from "./common/Constant.js";

const theme = createTheme({
  palette: {
    primary: {
      main: "#eb564a",
      dark: "#bf000a",
    },
    secondary: {
      main: "#bf000a",
    },
  },
  typography: {
    fontFamily: [
      '"Helvetica Neue Regular"',
      '"Helvetica Neue"',
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
  },
});

const App = () => {
  const [isPageChange, setIsPageChange] = useState(false);
  const dispatch = useDispatch();
  // TODO: Revisar esto, solo con descomentar rompe la reproduccion refresca ante cualquier cambio de usr
  // const usr = useSelector(state => state.usr);
  const roles = useSelector((state: any) => state.roles);
  const [authChecked, setAuthChecked] = useState<boolean | null>(null);
  const checkUser = () => setAuthChecked(true);
  const unCheckUser = () => setAuthChecked(false);

  useEffect(() => {
    const listen = history.listen(() => {
      setIsPageChange(prevState => !prevState);
    });
    return listen;
  }, []);

  useEffect(() => {
    Firebase.onAuthStateChanged((userProvided: any) => {
      if (userProvided) {
        dispatch(loginAction(userProvided, checkUser, unCheckUser));
      } else {
        dispatch(logoutAction(unCheckUser));
      }
    });
  }, [dispatch]);

  useEffect(() => {
    const auth = localStorage.getItem("Authorization");
    if (auth) {
      const payload = JSON.parse(window.atob(auth.split(".")[1]));
      if (payload.exp * 1000 - new Date().getTime() <= 0) {
        Firebase.onAuthStateChanged((userProvided: any) => {
          if (userProvided) {
            dispatch(
              loginAction(userProvided, checkUser, unCheckUser, () =>
                window.location.reload(),
              ),
            );
          } else {
            dispatch(logoutAction(unCheckUser));
          }
        });
      }
    }
  }, [dispatch, isPageChange]);

  const PublicRoutes = ({ match }: { match: any }) => {
    return (
      <Switch>
        <Route exact path={`${match.url}`}>
          {authChecked ? <Redirect to="/home" /> : <HomeNoUser />}
        </Route>
        <Route exact path={`${match.url}home`}>
          <Home />
        </Route>
        <Route exact path={`${match.url}explore`}>
          <Explore />
        </Route>
        <Route exact path={`${match.url}login`}>
          <Login />
        </Route>
        <Route exact path={`${match.url}reset-password`}>
          <ResetPassword />
        </Route>
        <Route exact path={`${match.url}cine-club`}>
          <CineClub />
        </Route>
        <Route exact path={`${match.url}faqs`}>
          <Faqs />
        </Route>
        <Route exact path={`${match.url}who`}>
          <WhoWeAre />
        </Route>
        <Route exact path={`${match.url}details`}>
          <MovieDetails freeMode={null} />
        </Route>
        <Route exact path={`${match.url}contact`}>
          <Contact authChecked={authChecked} />
        </Route>
        <Route exact path={`${match.url}result`}>
          <ResultSearch />
        </Route>
        {/* Routes Festival */}
        {/* <Route exact path={`${match.url}festival`}>
          <Festival title="Festival Arfecine" description="" />
        </Route> */}
        <Route exact path={`${match.url}watch-festival`}>
          <Watch freeMode />
        </Route>
        {/* <Route exact path={`${match.url}details-festival`}>
          <MovieDetails authChecked={authChecked} freeMode />
        </Route> */}
      </Switch>
    );
  };

  const ProtectedRoutes = ({ match }: { match: any }) => {
    return (
      <Switch>
        <Route exact path={`${match.url}watch`} component={Watch} />
        <Route exact path={`${match.url}my-list`} component={MyList} />
        <Route exact path={`${match.url}profile`} component={Profile} />
        <Route exact path={`${match.url}sync-device`} component={SyncDevice} />
        <Route
          exact
          path={`${match.url}exclusive`}
          component={ExclusiveSection}
        />
        {(roles.isAdmin || roles.isSeo) && (
          <Route
            path={`${match.url}panel-control`}
            component={PanelControlRoutes}
          />
        )}
      </Switch>
    );
  };

  const PanelControlRoutes = ({ match }: { match: any }) => {
    return (
      <Switch>
        <Route exact path={`${match.url}`} component={PanelControl} />
        {roles.isAdmin ? (
          <Fragment>
            <Route
              exact
              path={`${match.url}/seo/edit-movie`}
              component={PanelControlSEO}
            />
            <Route
              exact
              path={`${match.url}/users`}
              component={PanelControlUsers}
            />
            <Route
              exact
              path={`${match.url}/notifications`}
              component={Notifications}
            />
            {/* <Route
          exact
          path={`${match.url}/upload-banner`}
          component={UploadBanner}
        /> */}
            <Route
              exact
              path={`${match.url}/upload-genre`}
              component={UploadGenre}
            />
            <Route
              exact
              path={`${match.url}/upload-subscription`}
              component={UploadSubscriptionType}
            />
            <Route
              exact
              path={`${match.url}/edit-subscriptions`}
              component={EditSubscriptions}
            />
            <Route
              exact
              path={`${match.url}/edit-slider`}
              component={EditSlider}
            />
            <Route
              exact
              path={`${match.url}/upload-user-free`}
              component={UploadUserFree}
            />
            <Route
              exact
              path={`${match.url}/delete-banner`}
              component={DeleteBanner}
            />
            <Route
              exact
              path={`${match.url}/delete-slider`}
              component={DeleteSlider}
            />
            <Route
              exact
              path={`${match.url}/delete-genre`}
              component={DeleteGenre}
            />
            <Route
              exact
              path={`${match.url}/delete-movie`}
              component={DeleteMovie}
            />
          </Fragment>
        ) : (
          roles.isSeo && (
            <Route
              exact
              path={`${match.url}/seo/edit-movie`}
              component={PanelControlSEO}
            />
          )
        )}
      </Switch>
    );
  };

  return (
    <ThemeProvider theme={theme}>
      <Router history={history}>
        <MaintenanceMode>
          <Switch>
            {authChecked === null ? (
              <LoaderApp open={true} />
            ) : authChecked ? (
              <Fragment>
                <Route path="/" component={PublicRoutes} />
                <Route path="/" component={ProtectedRoutes} />
                {/* <Redirect to={"/home"} /> */}
              </Fragment>
            ) : (
              <Fragment>
                <Route path="/" component={PublicRoutes} />
                {/* <Redirect to={"/"} /> */}
              </Fragment>
            )}
          </Switch>
          <ModalPayment />
        </MaintenanceMode>
      </Router>
    </ThemeProvider>
  );
};

export default App;
